import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';
import Post from '../components/Post';

class BlogList extends React.Component {
	getSEOData = siteMetadata => {
		const slug = '/' + this.props['*'];
		const category = this.props.pageContext.categories.filter(c => c.slug === slug);

		if (category.length !== 0) {
			siteMetadata.title = category[0].name + ' | ' + siteMetadata.title;
		}

		siteMetadata.cover = siteMetadata.siteUrl + this.props.data.allMarkdownRemark.edges[0].node.frontmatter.cover.childImageSharp.fluid.srcSet.split(',')[0].split(' ')[0];

		return siteMetadata;
	}

	render() {
		const {data, pageContext} = this.props;

		return (
			<Layout
				categories={pageContext.categories}
				page={{next : pageContext.next, prev : pageContext.prev}}
				seo={this.getSEOData(data.site.siteMetadata)}
			>
				{data.allMarkdownRemark.edges.map(p => (<Post key={p.node.fields.slug} post={p.node}/>))}
			</Layout>
		);
	}
}

BlogList.propTypes = {
	data : PropTypes.shape({
		site : PropTypes.object.isRequired,
		allMarkdownRemark : PropTypes.object.isRequired
	}).isRequired,
	pageContext   : PropTypes.shape({
		next : PropTypes.number,
		prev : PropTypes.number,
		categories : PropTypes.array.isRequired
	}).isRequired,
	'*'   : PropTypes.string.isRequired
};

export default BlogList;
export const pageQuery = graphql`
	query BlogListQuery($skip: Int!, $limit: Int!, $filter : [String!]!) {
		site {
			siteMetadata {
				title
				description
				siteUrl
				cover
			}
	    }
		allMarkdownRemark(
			sort: {fields : [frontmatter___date], order : DESC}
			filter: {frontmatter : {draft : {eq : false}, category : {in : $filter}}}
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					excerpt
					fields {
						slug
						readingTime {
							text
						}
					}
					frontmatter {
						date(formatString: "MMM D")
						title
						cover {
							childImageSharp {
              					fluid(maxWidth: 1024) {
                					...GatsbyImageSharpFluid
              					}
            				}
						}
					}
				}
			}
		}
	}
`;
